import Dom = require("Everlaw/Dom");
import BasicRadio = require("Everlaw/UI/BasicRadio");
import Checkbox = require("Everlaw/UI/Checkbox");
import { BillingMode } from "Everlaw/BillingMode";

export { BillingMode };

export function standardBilling(billingMode: BillingMode): boolean {
    return (
        billingMode === BillingMode.STANDARD
        || billingMode === BillingMode.STANDARD_EXCLUDING_PRODUCED
    );
}

export function totalBilling(billingMode: BillingMode): boolean {
    return (
        billingMode === BillingMode.TOTAL_STORAGE
        || billingMode === BillingMode.TOTAL_STORAGE_EXCLUDING_PRODUCED
    );
}

export function productionExclusionBilling(billingMode: BillingMode): boolean {
    return (
        billingMode === BillingMode.STANDARD_EXCLUDING_PRODUCED
        || billingMode === BillingMode.TOTAL_STORAGE_EXCLUDING_PRODUCED
    );
}

export class BillingDialog {
    static description = {
        standard: `Documents uploaded through the native uploader are billed by native size. 
                       Documents uploaded through the processed uploader are billed by the size of native, images/PDF, and text.`,
        total: `Documents uploaded through the native uploader are billed by native size, image, and text size. 
                       Documents uploaded through the processed uploader are billed by the size of native, images/PDF, and text.`,
        excludeProduced: `Exclude documents produced on Everlaw from billing and automatically delete produced documents 
                       when corresponding source documents are deleted`,
    };
    static verbose: Record<keyof typeof BillingMode, string> = {
        STANDARD: "standard billing",
        STANDARD_EXCLUDING_PRODUCED: "standard excluding produced documents from billing",
        TOTAL_STORAGE: "total storage billing",
        TOTAL_STORAGE_EXCLUDING_PRODUCED: "total storage excluding produced documents from billing",
    };
    static standard = {
        id: BillingMode.STANDARD,
        display: () =>
            Dom.div(
                Dom.div("Standard"),
                Dom.div({ class: "billing-dialog-message" }, BillingDialog.description.standard),
            ),
    };
    static total = {
        id: BillingMode.TOTAL_STORAGE,
        display: () =>
            Dom.div(
                Dom.div("Total"),
                Dom.div({ class: "billing-dialog-message" }, BillingDialog.description.total),
            ),
    };
    static excludeProduced = {
        display: () =>
            Dom.div(
                Dom.div("Exclude produced documents from billing"),
                Dom.div(
                    { class: "billing-dialog-message" },
                    BillingDialog.description.excludeProduced,
                ),
            ),
    };
    billingModel: BasicRadio;
    producedBillingCheckbox: Checkbox;

    constructor(mode: BillingMode) {
        this.billingModel = new BasicRadio([BillingDialog.standard, BillingDialog.total]);
        this.producedBillingCheckbox = new Checkbox({
            label: BillingDialog.excludeProduced.display(),
        });
        this.billingMode = mode;
    }

    get billingMode(): BillingMode | null {
        if (this.billingModel.getSelected() === null) {
            return null;
        }
        if (this.billingModel.getSelectedId() === BillingDialog.total.id) {
            return this.producedBillingCheckbox.isSet()
                ? BillingMode.TOTAL_STORAGE_EXCLUDING_PRODUCED
                : BillingMode.TOTAL_STORAGE;
        }
        return this.producedBillingCheckbox.isSet()
            ? BillingMode.STANDARD_EXCLUDING_PRODUCED
            : BillingMode.STANDARD;
    }

    set billingMode(billingMode: BillingMode) {
        // Avoid spurious onChange handler invocations
        if (this.billingMode !== billingMode) {
            this.billingModel.select(
                standardBilling(billingMode) ? BillingDialog.standard : BillingDialog.total,
            );
            this.producedBillingCheckbox.set(productionExclusionBilling(billingMode));
        }
    }
}
